<template>
  <div>
    <v-row align-content="center" no-gutters class="mx-2">
      <v-col cols="12" class="my-3 justify-center">
        <strong class="d-flex justify-center">
          Volume journalier moyenné pour la semaine en cours
        </strong></v-col
      >
      <v-col cols="12">
        <line-c
          v-if="loadedPie"
          :style="'height: 200px; max-height: 200px;'"
          :chart-data="chartdataJour"
          :options="optionsChartJour"
        />
      </v-col>
      <v-col cols="12" class="my-3 justify-center">
        <strong class="d-flex justify-center">
          Volume horaire de la semaine en cours
        </strong></v-col
      >
      <v-col cols="12" sm="6">
        <pie-c
          v-if="loadedPie"
          :style="'height: 500px; max-height: 500px;'"
          :chart-data="chartdataPie"
        />
      </v-col>
      <v-col cols="12" sm="6" align-self="center">
        <v-data-table
          v-if="shown"
          :loading="!shown"
          color="primary"
          :headers="headersWeekStatPercentage"
          :items="weekStatPercentage"
          density="compact"
          item-key="label"
        ></v-data-table>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row align-content="center" no-gutters class="mx-2">
      <v-col cols="12" class="justify-center">
        <strong class="d-flex justify-center" :style="'align-items: center'">
          Volume horaire par
          <v-select
            v-model="periode"
            density="compact"
            flat
            variant="solo"
            rounded
            hide-details
            max-width="150"
            name="year"
            :items="[
              { title: 'semaine', value: 'week' },
              { title: 'mois', value: 'month' }
            ]"
          >
          </v-select></strong></v-col
      ><v-col cols="12"
        ><strong class="d-flex justify-center" :style="'align-items: center'"
          >( année
          <v-select
            v-model="year"
            density="compact"
            flat
            variant="solo"
            rounded
            hide-details
            max-width="100"
            name="year"
            :items="[2024, 2025, 2026, 2027, 2028]"
          >
          </v-select
          >)
        </strong></v-col
      >
      <v-col cols="12">
        <line-c
          v-if="loaded"
          :style="'height: 500px; max-height: 500px;'"
          :chart-data="chartdata"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import LineC from '../../Components/Graphs/LineC.vue';
import PieC from '../../Components/Graphs/PieC.vue';
import {
  getStatsLocation,
  getStatsLocationPieWeek
} from '../../../api/methods/statsLocations';
import { watch, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import log from 'loglevel';
import { DateTime } from 'luxon';

const store = useStore();

let year = ref(2024);
let periode = ref('week');

let loaded = ref(false);
let loadedPie = ref(false);

let chartdata = ref({});

let optionsChartJour = ref({
  transitions: {
    show: {
      animations: {
        x: {
          from: 0
        },
        y: {
          from: 0
        }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: ''
    },
    legend: {
      display: false
    }
  },

  indexAxis: 'y',
  scales: {
    y: {
      stacked: true,
      title: {
        display: false,
        text: "Nb d'heures"
      }
    },
    x: {
      stacked: true,
      min: 0,
      display: true,
      title: {
        display: true,
        text: "Nb d'heures"
      }
    }
  }
});

let chartdataPie = ref({});
let chartdataJour = ref({});
let weekStatPercentage = ref<Array<Object>>([{}]);
let headersWeekStatPercentage = [
  {
    title: 'Evénement',
    sortable: false,
    align: 'center' as const,
    key: 'label'
  },
  { title: "Nb d'heures", key: 'total', align: 'end' as const, sortable: true },
  { title: '%', key: 'percentage', align: 'end' as const, sortable: true }
];

const shown = computed(() => {
  return weekStatPercentage.value?.length > 0;
});

watch(year, () => {
  getStatsLocation
    .callAsync({
      year: year.value,
      periode: periode.value
    })
    .then((result: Object) => {
      chartdata.value = result;
      log.info(
        '[ViewStatsHumans.vue][watch][year][chartdata]',
        chartdata.value
      );
    })
    .catch((error: string) => {
      log.error('[ViewStatsHumans.vue][watch year]', error);
    });
});

watch(periode, () => {
  getStatsLocation
    .callAsync({
      year: year.value,
      periode: periode.value
    })
    .then((result: Object) => {
      chartdata.value = result;
      log.info(
        '[ViewStatsHumans.vue][watch][year][chartdata]',
        chartdata.value
      );
    })
    .catch((error: string) => {
      log.error('[ViewStatsHumans.vue][watch year]', error);
    });
});

onMounted(async () => {
  store.commit('setAppNavTitle', 'Statistiques');
  chartdata.value = await getStatsLocation.callAsync({
    year: year.value
  });

  loaded.value = true;

  const {
    chartdata: pieData,
    weekStatPercentage: weekData,
    chartdataJour: jourData
  } = await getStatsLocationPieWeek.callAsync({
    week: parseInt(DateTime.now().toFormat('W'))
  });
  chartdataPie.value = pieData;
  weekStatPercentage.value = weekData;
  chartdataJour.value = jourData;

  loadedPie.value = true;

  log.info('[ViewStatsHumans.vue][onMounted][chartdata]', chartdataPie.value);
});
</script>

<style lang="scss" scoped>
div.Title {
  font-size: 100%;
  font-weight: 500;
}

.card {
  border: 1px;
  border-color: #000000;
  border-style: solid;
  border-radius: 5%;
}
</style>
